import React, { useState } from "react";
import { withTranslation } from "react-i18next";

// drawer
import Button from "../../../components/Buttons/Button";
import WideDrawer from "../../../components/Drawer/WideDrawer";
import Header from "../../../components/Drawer/Header";
import Footer from "../../../components/Drawer/Footer";

// form
import Structure from "../../../components/Forms/Structure";
import { useForm } from "react-hook-form";
import cogoToast from "cogo-toast";

// requests
import { deleteSchool, getSchoolById, patchSchool } from "../../requests/school";
import TabsUnderline from "../../../components/Tabs/TabsUnderline";
import Table from "../../../components/Tables/Table";

const UpdateSchool = ({ t, TriggerComponent, id, reloadData }) => {
  const tabs = [
    {
      id: "informations",
      name: t("Informations générales"),
    }
    ,
    {
      id: "licences",
      name: t("Licences"),
    },
  ]

  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm();
  const [loadingDeletion, setLoadingDeletion] = useState(false);
  const [otheradminSelected, setOtherAdminSelected] = useState(false);
  const [tabSelected, setTabSelected] = useState("informations")
  const [school, setSchool] = useState()

  function handleOnOpen() {
    getSchoolById(id).then(schoolAPI => {
      setSchool(schoolAPI)
      reset({
        uai: schoolAPI.uai,
        intitule: schoolAPI.intitule,
        ville: schoolAPI.ville,
        domaine: schoolAPI.domaine,
        GroupeAADStaff: schoolAPI.groupeAadStaff,
        GroupeAADEleves: schoolAPI.groupeAadEleves,
        administrateur: schoolAPI.administrateur,
        responsable: schoolAPI.responsable,
        responsable_role: schoolAPI.responsable_role,
        nom_fournisseur: schoolAPI.nom_fournisseur,
        date_souscription: schoolAPI.date_souscription ? schoolAPI.date_souscription.split("T")[0] : null
      });

      if (schoolAPI.responsable) {
        setOtherAdminSelected(true)
      }
    })
  }

  function onSubmit(data, reset, setOpen) {
    patchSchool(data).then(res => {
      if (res.ok) {
        cogoToast.success(
          t("School updated"),
          { position: "bottom-right" }
        )
        return res.json()
      } else {
        cogoToast.error(
          t("Error on student update") + " : " + res.status,
          { position: "bottom-right" }
        )
      }
    }).then(res => {
      if (res) {
        reset();
        setOpen(false);
        reloadData([res]);
      }
    })
  }

  function handleDelete(setOpen) {
    setLoadingDeletion(true);
    deleteSchool(id).then(res => {
      if (res.ok) {
        cogoToast.success(
          t("School deleted"),
          { position: "bottom-right" }
        )
        reset();
        setOpen(false);
        reloadData([{ id: id, deleted: true }]);
      } else {
        cogoToast.error(t("Error on school deletion") + " : " + res.status,
          { position: "bottom-right" });
      }
    })
  }

  return (
    <WideDrawer handleOnOpen={handleOnOpen} TriggerComponent={TriggerComponent}>
      {(setOpen) => (
        <form
          className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll"
          onSubmit={handleSubmit((data) => onSubmit(data, reset, setOpen), reset)}
        >
          <div className="flex-1">
            <Header title={t("School")} setOpen={setOpen}
              buttons={
                <>
                  <Button loading={loadingDeletion}
                    className="ml-2 mr-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    onClick={() => { handleDelete(setOpen) }}
                  >{t("Delete")}</Button>
                </>}
            />
            <TabsUnderline
              tabs={tabs}
              selectedTab={tabSelected}
              setSelectedTab={(value) => {
                setTabSelected(value)
              }}
              actions={[]}
            />
            {tabSelected === "informations" &&
              <div>
                <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                  <Structure
                    id="uai"
                    title={t("UAI") + " *"}
                    error={errors ? errors.uai : null}
                    component={() => (
                      <input
                        name="uai"
                        id="input-uai"
                        type="text"
                        required
                        className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
                        {...register("uai", { required: true })}
                      />
                    )}
                  />
                  <Structure
                    id="intitule"
                    title={t("Intitule") + " *"}
                    error={errors ? errors.intitule : null}
                    component={() => (
                      <input
                        name="intitule"
                        id="input-intitule"
                        type="text"
                        required
                        className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
                        {...register("intitule", { required: true })}
                      />
                    )}
                  />
                  <Structure
                    id="ville"
                    error={errors ? errors.ville : null}
                    title={t("City") + " *"}
                    component={() => (
                      <input
                        name="ville"
                        id="input-ville"
                        type="text"
                        required
                        className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
                        {...register("ville", { required: true })}
                      />
                    )}
                  />
                  <Structure
                    id="domaine"
                    error={errors ? errors.domaine : null}
                    title={t("Domain") + " *"}
                    component={() => (
                      <input
                        name="domaine"
                        id="input-domaine"
                        type="text"
                        required
                        className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
                        {...register("domaine", { required: true })}
                      />
                    )}
                  />
                  <Structure
                    id="GroupeAADStaff"
                    error={errors ? errors.GroupeAADStaff : null}
                    title={t("GroupeAADStaff") + " *"}
                    component={() => (
                      <input
                        name="GroupeAADStaff"
                        id="input-GroupeAADStaff"
                        type="text"
                        required
                        className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
                        {...register("GroupeAADStaff", { required: true })}
                      />
                    )}
                  />
                  <Structure
                    id="GroupeAADEleves"
                    error={errors ? errors.GroupeAADEleves : null}
                    title={t("GroupeAADEleves") + " *"}
                    component={() => (
                      <input
                        name="GroupeAADEleves"
                        id="input-GroupeAADEleves"
                        type="text"
                        required
                        className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
                        {...register("GroupeAADEleves", { required: true })}
                      />
                    )}
                  />
                  <Structure
                    id="nom_fournisseur"
                    error={errors.nom_fournisseur}
                    title={t("Nom du fournisseur") + " *"}
                    component={() => (
                      <input
                        name="nom_fournisseur"
                        id="input-nom_fournisseur"
                        type="text"
                        required
                        className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
                        {...register("nom_fournisseur", { required: true })}
                      />
                    )}
                  />
                  <Structure
                    id="date_souscription"
                    error={errors.date_souscription}
                    title={t("Date de souscription") + " *"}
                    component={() => (
                      <input
                        name="date_souscription"
                        id="input-date_souscription"
                        type="date"
                        required
                        className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
                        {...register("date_souscription", { required: true })}
                      />
                    )}
                  />
                  <Structure
                    id="administrateur"
                    error={errors ? errors.administrateur : null}
                    title={t("Admin") + " *"}
                    component={() => (
                      <input
                        name="administrateur"
                        id="input-administrateur"
                        type="text"
                        required
                        className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
                        {...register("administrateur", { required: true })}
                      />
                    )}
                  />
                  <Structure
                    id="cbx_responsable"
                    title={t("Ajouter un autre responsable")}
                    required="true"
                    component={() =>
                      <input type="checkbox" id="serie_du_baccalaureat_obtenu" defaultChecked={otheradminSelected} onClick={() => {
                        setOtherAdminSelected(v => {
                          if (!v === false) {
                            setValue("responsable_role", null)
                            setValue("responsable", null)
                          }
                          return !v
                        });

                      }} />
                    }
                  />
                  {otheradminSelected && <><Structure
                    id="responsable_role"
                    error={errors.responsable_role}
                    title={t("Responsable role")}
                    description={"Admin : accès à tous les onglets (comptes élèves, comptes établissement, classe teams) / Responsable : accès uniquement à ce qui concerne les élèves (comptes élèves, classe teams)"}
                    component={() => (
                      <select
                        name="responsable_role"
                        id="input-responsable_role"
                        type="text"
                        className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
                        {...register("responsable_role")}
                      >
                        <option key={1} value={1}>{t("Administrateur")}</option>
                        <option key={2} value={2}>{t("Responsable")}</option>
                      </select>
                    )} />
                    <Structure
                      id="responsable"
                      error={errors.responsable}
                      title={t("Responsable")}
                      component={() => (
                        <input
                          name="responsable"
                          id="input-responsable"
                          type="text"
                          className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
                          {...register("responsable")}
                        />
                      )} /></>}
                </div>
              </div>}
            {tabSelected === "licences" && <div className="items-center h-60 justify-center flex">
              <h1 class="text-5xl text-gray-600 font-bold">
                Coming Soon
              </h1>
              {/* {school.licences && school.licences.length ?
                <ul role="list" className="divide-y divide-gray-100">
                  {school.licences.map(licence => {
                    return <li key={licence.id_licence} className="flex items-center justify-between gap-x-6 py-5 mx-3">
                                            <div className="min-w-0">
                        <div className="flex items-start gap-x-3">
                          <p className="text-sm font-semibold leading-6 text-gray-900">{licence.label}</p>
                        </div>
                        <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                          <p className="whitespace-nowrap">
                            {licence.id_licence}
                          </p>
                        </div>
                      </div>
                      <div>{licence.amount}</div>
                      <div className="flex flex-none items-center gap-x-4">
                        <div
                          className="cursor-pointer rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                        >
                          {t("Modifier")}
                        </div>
                      </div>
                    </li>
                  })}
                </ul>
                : null} */}
            </div>}
          </div>
          {tabSelected === "informations" && <Footer>
            <>
              <button
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={() => { reset(); setOpen(false); }}
              >
                {t("Cancel")}
              </button>
              <Button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                {t("Update")}
              </Button>
            </>
          </Footer>}
        </form>
      )
      }
    </WideDrawer >
  );
};

export default React.memo(withTranslation()(UpdateSchool));
